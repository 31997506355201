<template>
  <div class="tree-item">
    <div class="tag" v-if="!item.children"
         :class="{
            'tag-selected' : isSelected,
            'tag-open' : isOpen,
            'tag-compact' : mode === 'selectedOnly'
         }">
      <span class="tag-checkbox"
            @click.stop="toggleTagSelection(item)"
            v-if="mode !== 'selectedOnly'"
      ></span>
      <span class="tag-name"><!--{{ this.selectedChildrenCount }}-->
        <span class="name">{{ item.name }}</span>
        <span class="remove-tag"
              v-if="mode === 'selectedOnly'"
              @click.stop="removeSelectedTag()">{{$t('button.delete')}}</span>
      </span>
    </div>
    <div v-else
         class="tag tag-has-children"
         @click.stop="toggle()"
         :class="{
          'tag-selected' : isSelected,
          'tag-open' : isOpen,
          'tag-compact' : mode === 'selectedOnly'
       }">
      <span class="tag-checkbox"
            @click.stop="toggleTagSelection(item)"
            v-if="mode !== 'selectedOnly'"
      ></span>
      <span class="tag-name">
        <span class="name">{{ item.name }}</span><!--{{ this.selectedChildrenCount }}-->
        <span class="remove-tag"
              v-if="mode === 'selectedOnly'"
              @click.stop="removeSelectedTag()">{{$t('button.delete')}}</span>
      </span>
      <div class="tag-children" v-show="isOpen">
        <choose-company-categories-tree-item
            v-for="(tag, index) in item.children"
            ref="tagChildren"
            :key="'item_' + item.id + '_' + index"
            :item="tag"
            :mode="mode"
            :selectedTagIds="selectedTagIds"
            @tagRemoved="$emit('tagRemoved')"
            @checkboxSelected="handleChildSelected($event)"
        ></choose-company-categories-tree-item>
      </div>
      <span class="fold-icon"></span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'chooseCompanyCategoriesTreeItem',
  inject: ['setTagSelected'],
  props: {
    mode: {
      type: String, // used mode names: selectedOnly
      default: 'allTagsMode' // just some string. Default mode name is not used yet
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedTagIds: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: function () {
    return {
      isOpen: false,
      isSelected: false,
      selectedChildren: [],
      selectedChildrenCount: 0 // counts only direct children
    }
  },
  mounted(){
    // setting initial value of local isSelected data property
    this.isSelected = this.selectedTagIds.indexOf(this.item.id) > -1;
  },
  methods: {

    /* Removes selected tag, minding parent-child relations
     * tries to use deselect() code, with the addition of
     * $emitting an event to be bulled up to returnTagSelection() method
     */
    removeSelectedTag(){

      this.deselect();
      this.$emit('tagRemoved');
    },

    /* Shows/hides child tags
     */
    toggle() {
      this.isOpen = !this.isOpen;
    },

    /* Toggles current tag selection
     */
    toggleTagSelection(tagInfo) {
      this.isSelected = !this.isSelected;

      this.sendSelectedState();

      if (this.$refs.tagChildren) {
        // Select all children
        if (this.isSelected) {
          for (let i = 0; i < this.$refs.tagChildren.length; i++) {
            this.$refs.tagChildren[i].select();
          }
          // if $refs.tagChildren is TRUEY, it means this.item.children also is TRUEY, no need to check here
          this.selectedChildrenCount = this.item.children.length;
        } else {
          for (let i = 0; i < this.$refs.tagChildren.length; i++) {
            this.$refs.tagChildren[i].deselect();
            this.selectedChildrenCount = 0;
          }
        }
      }

      // Tell parent about the change
      this.$emit('checkboxSelected', {
        isSelected: this.isSelected, // true || false checkbox value
        tag: tagInfo // a tag object with tag data fields
      });

    },

    /* Uses provide/injected method with tag id
     */
    sendSelectedState() {
      this.setTagSelected(this.item.id, this.isSelected);
    },

    /* Selects this tag and all its child tags
     */
    select() {
      this.isSelected = true;
      this.sendSelectedState();
      if (!this.$refs.tagChildren) return;
      for (let i = 0; i < this.$refs.tagChildren.length; i++) {
        this.$refs.tagChildren[i].select();
      }
      // if $refs.tagChildren is TRUEY, it means this.item.children also is TRUEY, no need to check here
      this.selectedChildrenCount = this.item.children.length;
    },

    /* De-selects this tag and all its child tags
     */
    deselect() {
      this.isSelected = false;
      this.sendSelectedState();
      this.selectedChildrenCount = 0;
      if (!this.$refs.tagChildren) return;
      for (let i = 0; i < this.$refs.tagChildren.length; i++) {
        this.$refs.tagChildren[i].deselect();
      }
    },

    /* Handles child tag selection event
     * Passes the event further
     */
    handleChildSelected(eventData) {
      if (eventData.isSelected) {
        this.isSelected = eventData.isSelected;
        this.selectedChildrenCount++;
      } else {
        this.selectedChildrenCount = this.selectedChildrenCount > 0 ? this.selectedChildrenCount - 1 : 0;
      }

      if (this.item.children && this.selectedChildrenCount < 1) {
        this.isSelected = false;
      }

      this.sendSelectedState();
      eventData.selectedChildrenCount = this.selectedChildrenCount;

      this.$emit('checkboxSelected', eventData);
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-item {
  padding-right: 4px;
}

.tag {
  position: relative;
  margin: 1rem 0 0;
  padding: 11px 0 11px 38px;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: #d8d8d8;

  &-compact {
    padding: 11px 0 6px;
  }

  &:first-child {
    margin-top: 0;
  }

  > .tag-checkbox {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #80848b;
    border-radius: 4px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 18px 0 -38px;
  }

  > .tag-name {
    display: inline-block;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.2;
    color: $ew-black;
    .name {
      margin-right: auto;
      padding-left: 10px;
    }
  }

  > .fold-icon {
    position: absolute;
    top: 16px;
    right: 19px;
    width: 20px;
    height: 9px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      opacity: 0.65;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 52%;
      background-color: $ew-blue;
      height: 2px;
      bottom: 0;
      left: 0;
      transform-origin: right center;
      transform: rotate(40deg);
    }

    &::after {
      left: 50%;
      transform-origin: left center;
      transform: rotate(-40deg);
    }
  }

  &-open {
    margin-bottom: 27px;

    /* stylelint-disable */
    > .fold-icon {
      transform: rotate(180deg);
    }
  }

  &-selected {
    > .tag-name {
      color: $ew-blue;
    }

    > .tag-checkbox {
      border-color: $ew-blue;
      position: relative;

      /* check icon without images */
      &::before,
      &::after {
        position: absolute;
        content: "";
        height: 2px;
        background-color: $ew-blue;
        bottom: 5px;
        width: 30%;
        left: 15%;
        transform-origin: center right;
        transform: rotate(45deg);
      }

      &::after {
        left: 42%;
        transform-origin: center left;
        transform: rotate(-45deg);
        width: 56%;
      }
    }
  }
}

.tag > .tag-children {
  padding-top: 14px;
  margin-bottom: -3px;

  .tree-item {
    padding-right: 0;
  }

  .tag {
    padding-top: 8px;
    padding-bottom: 9px;
    border-width: 0;

    .tag-name {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.25;
    }
  }

}

.tag-compact {

  .fold-icon {
    right: 0;
    transform: rotate(-90deg);
  }

  .tag-name {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 38px;
  }

  .remove-tag {
    background-color: $ew-red;
    cursor: pointer;
    //display: none;
    //position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 50px;
    border-radius: 99px;
    //color: red;
    //background-color: #fff;
    box-shadow: 0px -3px 16px rgba(189, 187, 187, 0.363718);
    //width: 19px;
    //height: 19px;
    text-align: center;
    line-height: 19px;

    text-align: center;
    line-height: 19px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: normal;
    color: white;
    display: block;
    width: 80px;

    &::before,
    &::after {
      //content: "";
      //height: 1px;
      //background-color: red;
      //width: 80%;
      //position: absolute;
      //left: 10%;
      //top: 50%;
      //margin-top: -1px;
      //transform-origin: center;
      //transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &:hover .remove-tag {
    display: block;
  }

  .tag-children {
    .tag {
      //padding: 0 0 4px 0;

      > .tag-name {

        font-size: 1.3rem;
        line-height: 1.2;
        padding-left: 10px;

        &::before {
          content: "●";
        }
      }

    }

    .fold-icon {
      top: 14px;
    }

    .tag-children {
      padding-left: 1em;
    }
  }

  &.tag-open {
    > .fold-icon {
      transform: rotate(0deg);
    }
  }

  &.tag-selected {
    > .tag-name {
      color: $ew-black;
    }
  }
}
</style>
